import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupMemberService {

  

  

  constructor(private authService: AuthService, private http: HttpClient) { }

  public getAllGroupMembers(active): Observable<any> {
    return this.http.get<any>(this.authService._getAllGroupMembers + '?active=' + active);
  }

  public saveGroupMembers(data) {
    return this.http.post(this.authService._saveGroupMembers, data);
  }

  public deleteGroupMembers(Id) {
    return this.http.post(this.authService._deleteGroupMembers, Id);
  }
}
