import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
  })
export class AccountTypeService {
    constructor(private authService: AuthService, private http: HttpClient) { }

     public getAccountTypes(isactive?: boolean): Observable<any> {
        if (isactive)
         {
            return this.http.get<any>(this.authService.getAllAccountType + '?active=' + isactive);
        } 
        else 
        {
            return this.http.get<any>(this.authService.getAllAccountType);
        }
    }  

    /* public getAccountTypeById(data): Observable<any> {
        return this.http.get<any>(this.authService.getAccountTypeById + '?id=' + data.id);
      } */

    public addAccountType(data) {
        return this.http.post<any>(this.authService.addAccountType, data);
      }

    /* public updateAccountType(data) {
        return this.http.put(this.authService.updateAccountType, data);
      } */

    public accountTypeUpdateSequence(data) {
        return this.http.put(this.authService.accountTypeUpdateSequence, data);
      }
    
    public deactivateAccountTypeById(deactivateId): Observable<any> {
        return this.http.put(this.authService.deactivateAccountTypeById + '?id=' + deactivateId, '')
      }
    
    public ActiveAccountTypeById(Id) {
        return this.http.put(this.authService.ActiveAccountTypeById + '?id=' + Id, '');
      } 

    public deleteAccounTypeById(deleteId): Observable<any> {
        return this.http.delete(this.authService.deleteAccountTypeById + '?id=' + deleteId);
      }


}