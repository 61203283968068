import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})

export class ExpenseService {

    constructor(private authService: AuthService, private http: HttpClient) { }

    public getBankDetails(isactive?: boolean): Observable<any> {

        if (isactive) {

            return this.http.get<any>(this.authService.getAllBank + '?active=' + isactive);

        }
        else {

            return this.http.get<any>(this.authService.getAllBank);
        }
    }

    public addBankType(data) {
        return this.http.post<any>(this.authService._addBankType, data);
    }

    public deactivBankType(deactivateId): Observable<any> {
        return this.http.put(this.authService._deactivateBankById + '?id=' + deactivateId, '')
    }

    public activateBankType(Id): Observable<any> {
        return this.http.put(this.authService._activateBankById + '?id=' + Id, '');
    }

    public deleteBank(Id): Observable<any> {
        return this.http.delete(this.authService._deleteBankType + '?id=' + Id);
    }
    public bankUpdateSequence(data) {
        return this.http.put(this.authService._updateSequenceBankType, data);
    }


}