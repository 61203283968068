import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SmsService {

    constructor(private authService: AuthService, private http: HttpClient) { }

    public sendDonationSms(data) {
        return this.http.post(this.authService.sendDonationSms, data);
    }
    
    public sendBulkSms(data) {
        return this.http.post(this.authService.sendBulkSms, data);
    }

    public sendBirthdayWishes(data) {
        return this.http.post(this.authService.sendBirthdayWishes, data);
    }

    public sendWeddingWishes(data) {
        return this.http.post(this.authService.sendWeddingWishes, data);
    }

    public sendAuctionSms(data) {
        return this.http.post(this.authService.sendAuctionSms, data);
    }

    public sendSmsSubscription(data) {
        return this.http.post(this.authService.sendSmsSubscription, data);
    }

    public getAllTemplate(reqUrl): Observable<any> {
        return this.http.get<any>(this.authService.getSmstemplate+reqUrl);
    }

    public getTemplateById(id): Observable<any> {
        return this.http.get(this.authService.getSmstemplateById + '?id=' + id)
      }
    

}